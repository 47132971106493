import React from "react";
import "./InformationBookSide.css";
import CallToBook from "components/CallToBook/CallToBook";

const InformationBookSide = ({ tour }) => {
  const transformString = (str) => {
    return str.toLowerCase().replace(/ /g, '-');
  };

  // Verificar si tour es null o undefined
  if (!tour) {
    return <div>Loading...</div>;
  }

  // const dashTitle = tour.title ? transformString(tour.title) : 'no-title';

  return (
    <div className="informationBookSide">
      {/* <KeyInformationComponent /> */}
      <CallToBook tour={tour} isSimple={true} 
      //  isInfo={true}
      />

    </div>
  );
};

export default InformationBookSide;

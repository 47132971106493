import React, { useCallback, useContext, useEffect, useState } from "react";
import SmoothSlider from "components/SmoothSlider/SmoothSlider";
import "./InformationDescriptionSide.css";

import { Mail, Phone } from "lucide-react";
import { motion } from "framer-motion";
import { PAccordion, PCarousel, PDivider, PSpinner } from "@porsche-design-system/components-react";
import { PTable, PTableBody, PTableCell, PTableRow } from '@porsche-design-system/components-react';
import axiosInstance from "axiosConfig";
import useTexts from "mainApp/assets/utils/hooks/useTexts";

import VitalInfoComponent from "components/VitalInfoComponent/VitalInfoComponent";
import CallToBook from "components/CallToBook/CallToBook";
import { spacingFluidLarge, spacingFluidMedium, spacingFluidSmall, spacingFluidXLarge, spacingFluidXSmall, spacingFluidXXLarge } from "@porsche-design-system/components-js/styles";
import { IsRotatedContext } from "mainApp/assets/utils/context/isRotatedContext";


const InformationDescriptionSide = ({ tour, isPhone }) => {
    const { isRotated } = useContext(IsRotatedContext);
    const { texts } = useTexts('tourInfo');
    const [images, setImages] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);

    const onUpdate1 = useCallback((e) => {
        setIsOpen1(e.detail.open);
    }, []);

    const onUpdate2 = useCallback((e) => {
        setIsOpen2(e.detail.open);
    }, []);

    const onUpdate3 = useCallback((e) => {
        setIsOpen3(e.detail.open);
    }, []);

    const onUpdate4 = useCallback((e) => {
        setIsOpen4(e.detail.open);
    }, []);

    useEffect(() => {
        if (tour) {
            fetchImages();
            fetchLocations();
        }
    }, [tour]);

    const fetchImages = async () => {
        const params = { UUID: tour.id };
        try {
            const response = await axiosInstance.get('/api/toursAPI/get-images', { params });
            setImages(response.data);
            setLoading(false);
        } catch (error) {
            console.log("response ERROR");
            setLoading(false);
        }
    };

    const fetchLocations = async () => {
        const params = { tourUUID: tour.id };
        try {
            const response = await axiosInstance.get('/api/toursAPI/get-locations', { params });
            setLocations(response.data);
            setLoading(false);
        } catch (error) {
            console.log("response ERROR Locations");
            setLoading(false);
        }
    };

    if (!tour) return null;
    const rotatedStyle = {
        minHeight: isRotated ? "" : "40svh",
    }
    return (
        <div className="informationDescriptionSide"
            style={{ gap: isPhone ? "" : spacingFluidSmall }}
        >
            {
                isPhone &&
                (
                    <CallToBook isSimple={true} isPhone={isPhone} tour={tour} />
                )
            }
            <div className="infoSliderContainer"
                style={{
                    marginTop: isPhone ? spacingFluidLarge : "",
                    marginBottom: isPhone ? spacingFluidSmall : "",
                    marginLeft: isPhone ? spacingFluidSmall : spacingFluidMedium,
                    marginRight: isPhone ? spacingFluidSmall : spacingFluidMedium,
                    ...rotatedStyle
                }}
            >
                {loading ? (
                    <div className="spinnerContainerInfo">
                        <PSpinner className="pspinnerInfo" />
                    </div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="smoothContainerInfoSide"
                        style={{ rotatedStyle }}
                    >
                        {/* <SmoothSlider images={images} className="smoothSliderStyle" /> */}
                        <SmoothSlider images={images} />

                    </motion.div>
                )}
            </div>
            {/* <div className="infoSliderContainer">
                {loading ? (
                    <div className="spinnerContainerInfo">
                        <PSpinner className="pspinnerInfo" />
                    </div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="smoothContainerInfoSide"
                    >
                        <SmoothSlider images={images} className="smoothSliderStyle" />
                    </motion.div>
                )}
            </div> */}

            <PDivider direction={"vertical"} className="vDivider" />

            <div className="infoArea"
                style={{ paddingTop: isPhone ? spacingFluidSmall : "" }}
            >
                <VitalInfoComponent tour={tour} isMulti={true} />

                <PDivider className="hDivider"
                    style={{
                        marginTop: isPhone ? spacingFluidSmall : "",
                        marginBottom: isPhone ? spacingFluidXSmall : "",
                        // marginLeft: isPhone ? spacingFluidSmall : spacingFluidMedium,
                        // marginRight: isPhone ? spacingFluidSmall : spacingFluidMedium,
                    }}
                />
                {/* Dynamic */}
                <PAccordion heading={texts?.accordion?.loc?.head || "Loading..."} open={isOpen1} onUpdate={onUpdate1}>
                    <PTable caption={texts?.accordion?.loc?.caption || "Loading..."}>
                        <PTableBody>
                            {locations.map((location, index) => (
                                <PTableRow key={index}>
                                    <PTableCell>
                                        {location.name}
                                    </PTableCell>
                                </PTableRow>
                            ))}
                        </PTableBody>
                    </PTable>
                </PAccordion>

                <PAccordion heading={texts?.accordion?.info?.head || "Loading..."} open={isOpen2} onUpdate={onUpdate2}>
                    <PTable caption={texts?.accordion?.info?.caption || "Loading..."}>
                        <PTableBody>
                            {Object.keys(texts?.accordion?.info?.content || {}).map((key, index) => (
                                <PTableRow key={index}>
                                    <PTableCell multiline={true}>
                                        {texts?.accordion?.info?.content[key] || "Loading..."}
                                    </PTableCell>
                                </PTableRow>
                            ))}
                        </PTableBody>
                    </PTable>
                </PAccordion>

                <PAccordion heading={texts?.accordion?.legal?.head || "Loading..."} open={isOpen3} onUpdate={onUpdate3}>
                    <PTable caption={texts?.accordion?.legal?.caption || "Loading..."}>
                        <PTableBody>
                            {Object.keys(texts?.accordion?.legal?.content || {}).map((key, index) => (
                                <PTableRow key={index}>
                                    <PTableCell multiline={true}>
                                        {texts?.accordion?.legal?.content[key] || "Loading..."}
                                    </PTableCell>
                                </PTableRow>
                            ))}
                        </PTableBody>
                    </PTable>
                </PAccordion>

                {/* Static */}
                <PAccordion heading={texts?.accordion?.further?.head || "Loading..."} open={isOpen4} onUpdate={onUpdate4}>
                    <PTable caption={texts?.accordion?.further?.caption || "Loading..."}>
                        <PTableBody>
                            <PTableRow>
                                <PTableCell multiline={false}>
                                    <p className="text-sm text-gray-700">{texts?.accordion?.further?.content || "Loading..."}</p>
                                </PTableCell>
                            </PTableRow>
                            <PTableRow>
                                <PTableCell>
                                    <div className="flex items-center space-x-3">
                                        <Phone className="h-5 w-5 text-gray-500" />
                                        &nbsp;
                                        <a href={`https://wa.me/${process.env.REACT_APP_PHONE1}`} className="text-sm text-gray-600 hover:text-gray-900">
                                            {process.env.REACT_APP_PHONE1}
                                        </a>
                                    </div>
                                </PTableCell>
                            </PTableRow>
                            <PTableRow>
                                <PTableCell>
                                    <div className="flex items-center space-x-3">
                                        <Mail className="h-5 w-5 text-gray-500" />
                                        &nbsp;
                                        <a href={`mailto:${process.env.REACT_APP_EMAIL}`} className="text-sm text-gray-600 hover:text-gray-900">
                                            {process.env.REACT_APP_EMAIL}
                                        </a>
                                    </div>
                                </PTableCell>
                            </PTableRow>
                        </PTableBody>
                    </PTable>
                </PAccordion>
            </div>
        </div >
    );
};

export default InformationDescriptionSide;

// StickyDrawer.jsx
import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import './StickyDrawer.css';
import { PButton, PDivider, PIcon, PSpinner, PText } from '@porsche-design-system/components-react';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';
import {
    dropShadowLowStyle,
    spacingFluidLarge,
    spacingFluidMedium,
    spacingFluidSmall,
    spacingFluidXLarge,
    spacingFluidXSmall,
} from '@porsche-design-system/components-js/styles';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import { fadeInStyles, tileBaseStyleDrop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';
import { IsRotatedContext } from 'mainApp/assets/utils/context/isRotatedContext';

const StickyDrawer = ({ children, price, currency, isProcessingPayment, spinnerText, isPhone }) => {
    const { texts } = useTexts('stickyDrawer');
    const { isRotated } = useContext(IsRotatedContext);
    const portalRoot = document.getElementById('portal-root');
    if (!portalRoot) {
        console.error(
            "No se encontró el elemento con id 'portal-root'. Asegúrate de haberlo añadido en index.html."
        );
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        if (!isProcessingPayment) {
            setIsOpen(!isOpen);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    // Agregar manejo del historial y evento popstate
    useEffect(() => {
        const handlePopState = (event) => {
            if (isOpen) {
                // Si el cajón está abierto y el usuario presiona atrás, cerramos el cajón
                setIsOpen(false);
            } else {
                // Si el cajón ya está cerrado, permitimos que el navegador proceda
                // Puedes agregar lógica adicional aquí si lo deseas
            }
        };

        // Agregar el evento listener
        window.addEventListener('popstate', handlePopState);

        // Actualizar el historial cuando el cajón se abre o cierra
        if (isOpen) {
            // Agregar un nuevo estado al historial
            window.history.pushState({ drawerOpen: true }, '');
        }

        // Limpiar el listener al desmontar el componente o al cambiar isOpen
        return () => {
            window.removeEventListener('popstate', handlePopState);

            // Si el cajón se cierra manualmente, retrocedemos en el historial
            if (isOpen) {
                window.history.back();
            }
        };
    }, [isOpen]);

    const handlers = useSwipeable({
        onSwipedUp: () => {
            if (!isProcessingPayment) {
                setIsOpen(true);
            }
        },
        onSwipedDown: () => {
            if (!isProcessingPayment) {
                setIsOpen(false);
            }
        },
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: false,
    });

    return ReactDOM.createPortal(
        <div className={`liberty ${isOpen ? 'open' : 'closed'}`} {...handlers}>
            {/* Agregamos el fondo que cierra el drawer al hacer clic */}
            {isOpen && !isProcessingPayment && (
                <div className="drawer-overlay" onClick={toggleDrawer}
                style={fadeInStyles}
                />
            )}

            <div
                className="stickyDrawerConfirmationScreen"
                style={{
                    gap: spacingFluidMedium,
                    padding: `${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidLarge} ${spacingFluidSmall}`,
                    transform: isOpen
                        ? ''
                        : isRotated
                        ? 'translateY(calc(80vh - 10vw))'
                        : 'translateY(calc(80vh - 10vh))',
                }}
            >
                {/* Divisor superior */}
                <PDivider className="dividerStickyDrawerConfirmationScreen" />

                {/* Sección handle con animaciones */}
                <CSSTransition
                    in={!isOpen}
                    timeout={{ enter: 300, exit: 100 }} // Duraciones para entrada y salida
                    classNames="fade-handle"
                    unmountOnExit
                >
                    <div
                        className="handle"
                        onClick={!isProcessingPayment ? toggleDrawer : null}
                        style={{
                            padding: spacingFluidSmall,
                            height: isRotated ? '10vw' : '10vh',
                        }}
                    >
                        <div className="slideUpIconContainerStickyDrawer">
                            <PIcon name="arrow-head-up" aria={{ 'aria-label': 'Arrow Head Up icon' }} />
                        </div>
                        <div className="textsAreaStickyDrawer">
                            <div
                                className="totalTextContainerStickyDrawer"
                                style={{ gap: spacingFluidMedium }}
                            >
                                <PText size="x-large">{texts?.texts?.total}</PText>
                                <PText size="x-large">
                                    {price}
                                    {currency}
                                </PText>
                            </div>

                            <PDivider />
                            <PText color="contrast-medium" size="x-small">
                                {texts?.texts?.included}
                            </PText>
                        </div>
                        <PButton onClick={toggleDrawer} disabled={isProcessingPayment}>
                            {texts?.purchaseButton || 'Comprar'}
                        </PButton>
                    </div>
                </CSSTransition>

                {/* Contenido adicional que se muestra cuando el drawer está abierto */}
                <CSSTransition
                    in={isOpen}
                    timeout={{ enter: 300, exit: 100 }} // Duraciones para entrada y salida
                    classNames="drawer-content"
                    unmountOnExit
                >
                    <div className="drawer-content" style={{ padding: `0 ${spacingFluidMedium} 0` }}>
                        <div className="slideDownIconContainerStickyDrawer">
                            <PIcon name="arrow-head-down" aria={{ 'aria-label': 'Arrow Head Down icon' }} />
                        </div>
                        {children}
                    </div>
                </CSSTransition>
            </div>
            {isProcessingPayment && (
                <div className="spinnerOverlayOnStickyDrawer">
                    <div
                        className="spinnerContainer spinnerContainerOnStickyDrawer"
                        style={{
                            ...tileBaseStyleDrop,
                            ...dropShadowLowStyle,
                            padding: spacingFluidXSmall,
                        }}
                    >
                        <PSpinner />
                        <PText>{spinnerText}</PText>
                    </div>
                </div>
            )}
        </div>,
        portalRoot
    );
};

export default StickyDrawer;

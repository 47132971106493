const useGetUserAgent = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    return 'PC';
}

export default useGetUserAgent;
import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ReservationCalendar.css'; // Asegúrate de que esta ruta es correcta
import { DateTime } from 'luxon';
import { PButtonPure, PSpinner } from '@porsche-design-system/components-react';
import BlurFade from '@/components/magicui/blur-fade';
import { IsRotatedContext } from 'mainApp/assets/utils/context/isRotatedContext';
import { spacingFluidLarge, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';
import axiosInstance from 'axiosConfig';

const ReservationCalendar = ({ onDaySelect, isPhone, tourUUID }) => {
  const [date, setDate] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const previousActiveStartDate = useRef(activeStartDate);
  const [currentView, setCurrentView] = useState('month');

  useEffect(() => {
    fetchDisabledDates(activeStartDate);
    previousActiveStartDate.current = activeStartDate;
  }, []);

  const fetchDisabledDates = async (date) => {
    setLoading(true);
    // const year = date.getFullYear();
    // const month = date.getMonth() + 1;
    try {
      // const params = { year, month };
      const response = await axiosInstance.get(`api/toursAPI/${tourUUID}/get-unavailable-days`);
      // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bookingsAPI/get-unavailable-days`, { params });
      // console.log(response.data);
      setDisabledDates(response.data);
    } catch (error) {
      console.error('Error fetching disabled dates', error);
    }
    setLoading(false);
  };

  const tileDisabled = ({ date, view }) => {

    // console.log(disabledDates);
    if (view === 'month') {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      const isPastDate = date < new Date().setHours(0, 0, 0, 0);
      return disabledDates.includes(dateString) || isPastDate || date.getMonth() !== activeStartDate.getMonth();
    }
    return false;
  };

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    const prevDate = previousActiveStartDate.current;
    const viewChanged = currentView !== view;

    if (viewChanged) {
      setCurrentView(view);
    }

    if (
      (prevDate.getFullYear() !== activeStartDate.getFullYear() ||
        prevDate.getMonth() !== activeStartDate.getMonth()) &&
      !viewChanged
    ) {
      setActiveStartDate(activeStartDate);
      fetchDisabledDates(activeStartDate);
      previousActiveStartDate.current = activeStartDate;
    }
  };

  const handleClickDay = (date) => {
    const dateTimeInMadrid = DateTime.fromJSDate(date, { zone: 'Europe/Madrid' });
    const epochTime = dateTimeInMadrid.toSeconds();
    onDaySelect(epochTime);
  };

  const goBack = () => {
    window.history.back();
  };

  const { isRotated } = useContext(IsRotatedContext);
  const rotatedStyle = {
    height: isRotated ? "100svw" : "100svh"
  };


  return (
    <BlurFade className="h-full relative w-full"
    >
      <PButtonPure
        className="back-button-calendar"
        // style={}
        icon="arrow-left"
        hideLabel={true}
        tabindex={-1}
        onClick={goBack}
        style={{ marginLeft: isPhone ? "" : spacingFluidXSmall }}
      />
      {
        loading ? (
          <>
            <PSpinner
              className="spinnerCalendar"
            />
          </>

        ) : (
          <BlurFade
            className="flex w-full justify-center relative"
            style={rotatedStyle}
          >
            <Calendar
              className={isPhone ? "react-calendar-mobile" : ""}
              onChange={setDate}
              value={date}
              tileDisabled={tileDisabled}
              onClickDay={handleClickDay}
              onActiveStartDateChange={handleActiveStartDateChange}
              activeStartDate={activeStartDate}
              onViewChange={({ activeStartDate, view }) => {
                setCurrentView(view);
                setActiveStartDate(activeStartDate);
              }}
            // className={tileBaseStyle}
            /></BlurFade>
        )
      }
    </BlurFade>
  );
};

export default ReservationCalendar;

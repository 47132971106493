// TuktukModelsSection.js
import React, { useEffect } from 'react';
import {
    PSelect,
    PSelectOption,
    PButton,
    PButtonPure,
    PSpinner,
    PText,
    PInlineNotification,
    PFieldsetWrapper,
} from '@porsche-design-system/components-react';
import './TuktukModelsSection.css';
import { spacingFluidLarge, spacingFluidSmall, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';

const TuktukModelsSection = ({
    loadingModels,
    modelsFetched,
    tuktukModels,
    formData,
    persons,
    adults,
    setFormData,
    addTuktukModel,
    removeTuktukModel,
    texts,
    textsExtra,
    textsHeader,
    setErrors,
}) => {
    // Actualizar el total de unidades y capacidad cuando se cambia el número de modelos o unidades
    console.log("persons on TukTuk");
    console.log(persons);
    useEffect(() => {
        const selectedModels = formData.tuktukModels.filter(model => model.value);

        const totalUnits = selectedModels.reduce((acc, model) => acc + model.units, 0);

        const totalCapacity = selectedModels.reduce((acc, model) => {
            const modelData = tuktukModels.find((m) => m.value === model.value);
            const maxPeople = modelData ? modelData.maxPeople : 0;
            return acc + model.units * maxPeople;
        }, 0);

        setFormData((prevFormData) => ({ ...prevFormData, totalUnits, totalCapacity }));
    }, [formData.tuktukModels, tuktukModels, setFormData]);

    // Filtrar solo los modelos que tienen un valor seleccionado
    const selectedModels = formData.tuktukModels.filter(model => model.value);

    const totalUnits = selectedModels.reduce((acc, model) => acc + model.units, 0);
    console.log("totalUnits");
    console.log(totalUnits);
    const totalModels = selectedModels.length;

    const totalCapacity = selectedModels.reduce((acc, model) => {
        const modelData = tuktukModels.find((m) => m.value === model.value);
        const maxPeople = modelData ? modelData.maxPeople : 0;
        return acc + model.units * maxPeople;
    }, 0);

    const maxUnitsReached = totalUnits >= adults; // Corregido
    const maxModelsReached = totalModels >= tuktukModels.length || totalUnits >= adults;
    const insufficientCapacity = totalCapacity < persons;
    console.log("totalCapacity");
    console.log(totalCapacity);
    console.log(persons);
    console.log(insufficientCapacity);
    // Determinar si hay algún error activo y actualizar setErrors
    useEffect(() => {
        if (insufficientCapacity || totalUnits > adults) { // Corregido
            setErrors(true);
        } else {
            setErrors(false);
        }
    }, [insufficientCapacity, maxUnitsReached, setErrors, totalUnits, adults]);

    if (loadingModels) {
        return (
            <div className="flex items-center space-x-2">
                <PSpinner />
                <PText>{texts?.loadingModels}</PText>
            </div>
        );
    }

    if (modelsFetched && tuktukModels.length === 0) {
        setErrors(true);
        return (
            <PInlineNotification
                dismissButton={false}
                state="error"
                message={texts?.error?.NoModelAvailable?.main}
                description={texts?.error?.NoModelAvailable?.explanation}
            />
        );
    }

    const validTuktukModels = tuktukModels.filter((option) => option.value);

    const resetUnits = (index) => {
        const newModels = [...formData.tuktukModels];
        newModels[index].units = 1;
        setFormData({ ...formData, tuktukModels: newModels });
    };

    return (
        // <div className='tuktukModelsSection'
        //     style={{ gap: spacingFluidXSmall }}
        // >

        <PFieldsetWrapper label={`${textsHeader?.vehicles} (${textsExtra?.passengers}: ${persons})`}>
            {formData.tuktukModels &&
                formData.tuktukModels.map((model, index) => {
                    const modelData = tuktukModels.find((m) => m.value === model.value);
                    const modelMaxAvailableUnits = modelData ? modelData.availableUnits : 0;

                    let unitsAvailableForThisModel;

                    if (totalUnits >= adults) {
                        unitsAvailableForThisModel = model.units;
                    } else {
                        unitsAvailableForThisModel = Math.min(
                            modelMaxAvailableUnits,
                            adults - totalUnits + model.units
                        );
                    }

                    unitsAvailableForThisModel = Math.max(unitsAvailableForThisModel, 1);

                    return (
                        <div key={index} className="flex items-end"
                            style={{ gap: spacingFluidSmall }}
                        >
                            <PSelect
                                name={`car-model-${model.id}`}
                                label={`${texts?.modelField} ${index + 1}`}
                                className="w-full"
                                value={model.value || ''}
                                onUpdate={(e) => {
                                    const newModels = [...formData.tuktukModels];
                                    const selectedValue = e.detail.value;
                                    const modelData = tuktukModels.find(m => m.value === selectedValue);

                                    if (modelData) {
                                        newModels[index] = {
                                            ...newModels[index],
                                            value: selectedValue,
                                            units: 1, // Reiniciar las unidades si se selecciona un nuevo modelo
                                            maxPeople: modelData.maxPeople, // Añadir maxPeople
                                            label: modelData.label, // Añadir el nombre del modelo
                                            availableUnits: modelData.availableUnits,
                                        };
                                    } else {
                                        newModels[index] = {
                                            ...newModels[index],
                                            value: selectedValue,
                                            units: 1,
                                        };
                                    }

                                    setFormData({ ...formData, tuktukModels: newModels });
                                }}
                                required
                                state={texts?.error?.[`tuktukModels.${index}.value`] ? 'error' : undefined} // Agregado
                                message={texts?.error?.[`tuktukModels.${index}.value`] ? texts.error[`tuktukModels.${index}.value`].join(', ') : undefined} // Agregado
                                style={{zIndex: 10}}
                            >
                                {validTuktukModels
                                    .filter(
                                        (option) =>
                                            !formData.tuktukModels.some(
                                                (selectedModel, idx) =>
                                                    selectedModel.value === option.value && idx !== index
                                            )
                                    )
                                    .map((option) => (
                                        <PSelectOption
                                            key={option.value}
                                            value={option.value}
                                            disabled={option.availableUnits <= 0}
                                            style={{zIndex: 10}}
                                        >
                                            {`${option.label} (${texts?.modelDescription?.passengers}: ${option.maxPeople > 1
                                                ? `1 - ${option.maxPeople}`
                                                : `${option.maxPeople}`
                                                }) - ${texts?.modelDescription?.availableUnits}: ${option.availableUnits < 0 ? 0 : option.availableUnits}`}

                                        </PSelectOption>
                                    ))}
                            </PSelect>

                            <PSelect
                                value={model.units.toString()}
                                onUpdate={(e) => {
                                    const newModels = [...formData.tuktukModels];
                                    const selectedUnits = parseInt(e.detail.value, 10);
                                    const maxAvailableUnits =
                                        totalUnits >= adults
                                            ? model.units
                                            : Math.min(
                                                modelMaxAvailableUnits,
                                                adults - totalUnits + model.units
                                            );

                                    newModels[index].units = Math.min(selectedUnits, maxAvailableUnits);
                                    setFormData({ ...formData, tuktukModels: newModels });
                                }}
                                name={`car-model-${model.id}`}
                                label="Cantidad"
                                className="w-[100px]"
                                disabled={!model.value}
                                state={texts?.error?.[`tuktukModels.${index}.units`] ? 'error' : undefined} // Agregado
                                message={texts?.error?.[`tuktukModels.${index}.units`] ? texts.error[`tuktukModels.${index}.units`].join(', ') : undefined} // Agregado
                            >
                                {Array.from({ length: modelMaxAvailableUnits }, (_, i) => {
                                    const optionUnits = i + 1;
                                    const newTotalUnits = totalUnits - model.units + optionUnits;
                                    const disabled = newTotalUnits > adults;
                                    return (
                                        <PSelectOption
                                            key={i}
                                            value={optionUnits.toString()}
                                            disabled={disabled}
                                        >
                                            {optionUnits}
                                        </PSelectOption>
                                    );
                                })}
                            </PSelect>

                            {formData.tuktukModels.length > 1 && (
                                <PButton
                                    icon="delete"
                                    hideLabel={true}
                                    onClick={() => removeTuktukModel(index)}
                                />
                            )}
                        </div>
                    );
                })}

            {!maxModelsReached && adults > 1 && (
                <PButtonPure icon="add" onClick={addTuktukModel}>
                    {texts?.add}
                </PButtonPure>
            )}

            {totalUnits > adults && (
                <PInlineNotification
                    dismissButton={false}
                    state="error"
                    heading={texts?.error?.TooMany?.main}
                    description={texts?.error?.TooMany?.explanation}
                />
            )}

            {insufficientCapacity && selectedModels.length > 0 && (
                <PInlineNotification
                    dismissButton={false}
                    state="error"
                    heading={`${texts?.error?.NoPlaces} ${persons - totalCapacity}`}
                />
            )}
        </PFieldsetWrapper>
    );
};

export default TuktukModelsSection;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import "./bento.css";
import { PButtonTile, PSpinner } from '@porsche-design-system/components-react';
import BlurFade from "@/components/magicui/blur-fade";
import { scrollToTop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';

const BentoCard = ({ title, images, Icon, description, price, duration, id, tourState, index, onImagesLoad, imageLoaded, callOnExit }) => {


  const transformString = (str) => {
    return str.toLowerCase().replace(/ /g, '-');
  };

  const dashTitle = transformString(title);
  
  return (
    <>
      {imageLoaded && (
        <Link
          to={`/tour/details/${dashTitle}`}
          state={tourState}
          key={title}
          onClick={callOnExit}
        >
          <BlurFade key={id} delay={0.25 + index * 0.05} inView>

            <PButtonTile label="Buttons" description={title} compact={true} onClick={scrollToTop}>
              <img src={images} alt={title} />
            </PButtonTile>
          </BlurFade>

        </Link>
      )}
      <img src={images} onLoad={onImagesLoad} style={{ display: 'none' }} alt="" />
    </>
  );
};

export { BentoCard };

import React from "react";
import InformationDescriptionSide from "../../../components/InformationFolder/InformationDescriptionSide/InformationDescriptionSide";
import InformationBookSide from "../../../components/InformationFolder/InformationBookSide/InformationBookSide";
import "./TourInformation.css";
import { useLocation } from 'react-router-dom';
import { BorderBeam } from "components/uiElements/UISpecialEffects/border-beam/border-beam";

const TourInformation = ({ isPhone }) => {
    const location = useLocation();
    const state = location.state || {};
    console.log(isPhone);

    const tourInformationStyling = {
        display: isPhone ? "flex" : "grid",
    };

    return (
        <div className="tourInformation"
            style={tourInformationStyling}
        >
            {/* <BorderBeam borderRadius="0.5rem" className="absolute" /> */}
            <InformationDescriptionSide tour={state} isPhone={isPhone} />
            {
                !isPhone &&
                (
                    <InformationBookSide tour={state} />
                )
            }
        </div>
    );
};

export default TourInformation;

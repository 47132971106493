import { PButtonPure, PIcon } from "@porsche-design-system/components-react";
import "./ClosingButton.css";

const ClosingButton = ({ onClick }) => {
 
    return (
        <PButtonPure hideLabel={true} icon="close" loading={false}
        className="dismiss"
        onClick={onClick}
         /> 
    )
}

export default ClosingButton;
import React, { useState, useEffect, useContext, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import './ReservationManager.css';
import ReservationCalendar from './ReservationCalendar';
import TimeSelection from './TimeSelection';
import ConfirmationScreen from './ConfirmationScreen';
import { motion, AnimatePresence } from 'framer-motion'; // Importa framer-motion
import { DateTime } from 'luxon';
import useIsPhone from 'mainApp/assets/utils/hooks/useIsPhone';
import { scrollToTop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';

const AppointmentContext = createContext();

const AppointmentProvider = ({ children }) => {
  const [appointmentDate, setAppointmentDate] = useState(null);

  return (
    <AppointmentContext.Provider value={{ appointmentDate, setAppointmentDate }}>
      {children}
    </AppointmentContext.Provider>
  );
};

const ReservationManager = () => {
  const isPhone = useIsPhone();
  const location = useLocation();
  const tour = location.state?.tour; // Access tour object from state
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [formData, setFormData] = useState({
    bookingStart: 0,
    bookingEnd: 0,
    tour: tour?.title || '',
    client: null,
    tourUUID: tour?.id || null,
    customPickUp: "",
    priceUnit: tour?.price || 2,
    sprice: tour?.sprice || null,
    persons: 0,
    phone: 0,
    adults: 0,
    minorsTotal: 0,
    minors: false,
    minors2to5: 0,
    minors6to10: 0,
    minors11to17: 0,
    tuktukModels: [],
    totalUnits: 0,
    country: "",
    name: "",
    email: "",
    hasMinors: false,
  });
  const { setAppointmentDate } = useContext(AppointmentContext);

  useEffect(() => {

    if (step === 3 && selectedDate && selectedTime) {
      const appointmentDateTime = DateTime.fromMillis(selectedDate * 1000, { zone: 'Europe/Madrid' }).set({
        hour: parseInt(selectedTime.split(':')[0], 10),
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      setAppointmentDate(appointmentDateTime.toJSDate());
    }
  }, [step, selectedDate, selectedTime, setAppointmentDate]);

  useEffect(() => {
    scrollToTop();
  }, [step])

  const handleDateSelect = (epochTime) => {
    setSelectedDate(epochTime);
    setStep(2);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setStep(3);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  // Configuración de animaciones
  const pageVariants = {
    generalOut: {
      opacity: 0,
      transition: {
        duration: 0.6,
        // delay: 0.3
      }
    },
  };

  const pageTransition = {
    type: 'spring',
    stiffness: 300,
    damping: 30
  };

  return (
    <div className="reservationManager">
      <header className="reservationManager-header ">
        <AnimatePresence wait>
          {step === 1 && (
            <motion.div
              key="step1"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className='preScreen'
            >
              <ReservationCalendar onDaySelect={handleDateSelect} isPhone={isPhone}
                tourUUID={tour.id}
              />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="step2"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className='preScreen'
            >
              <TimeSelection date={selectedDate} onTimeSelect={handleTimeSelect} onBack={handleBack} isPhone={isPhone} tourUUID={tour.id} />
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              key="step3"
              variants={pageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={pageTransition}
              className='preScreen'
            >
              <ConfirmationScreen
                date={DateTime.fromMillis(selectedDate * 1000, { zone: 'Europe/Madrid' }).toISODate()}
                time={selectedTime}
                // onConfirmPayment={handleCheckout}
                onBack={handleBack}
                formData={formData}
                setFormData={setFormData}
                secondsEpoch={selectedDate}
                isPhone={isPhone}
                tourDuration={tour.duration}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </header>
    </div>
  );
};

const WrappedReservationManager = () => (
  <AppointmentProvider>
    <ReservationManager />
  </AppointmentProvider>
);

export { AppointmentContext, WrappedReservationManager as default };

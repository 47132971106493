// MinorsSection.js
import React from 'react';
import { PButton, PButtonPure, PText, PTextFieldWrapper } from '@porsche-design-system/components-react';
import './MinorsSection.css';
import { spacingFluidSmall, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';

const MinorsSection = ({
    minors,
    handleAddMinor,
    handleMinorAgeChange,
    handleRemoveMinor,
    texts,
    validationMessages
}) => {
    const preventInvalidCharacters = (e) => {
        if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
        }
    };

    // Función para incrementar la edad
    const incrementAge = (index) => {
        const currentAge = parseInt(minors[index].age, 10) || 2; // Default a 2 si no hay valor
        if (currentAge < 17) {
            handleMinorAgeChange(index, (currentAge + 1).toString());
        }
    };

    // Función para decrementar la edad
    const decrementAge = (index) => {
        const currentAge = parseInt(minors[index].age, 10) || 2;
        if (currentAge > 2) {
            handleMinorAgeChange(index, (currentAge - 1).toString());
        }
    };

    return (
        <div className="minorsData">
            {!minors.length ? (
                <div className="flex justify-start items-center"
                    style={{ gap: spacingFluidXSmall }}
                >
                    <PText
                        style={{ marginTop: spacingFluidXSmall }}
                    //  className='mr-0.5'
                    >{texts?.head}:</PText>
                    <PButtonPure
                        style={{ marginTop: spacingFluidXSmall }}
                        icon="add" onClick={handleAddMinor}>{texts?.add}</PButtonPure>
                </div>
            ) : (
                <PText
                    style={{ marginTop: spacingFluidXSmall }}
                // className='mr-0.5'
                >{texts?.head}:</PText>
            )}

            <div className="">
                {minors.map((minor, index) => (
                    <div
                        key={index}
                        className="flex items-end justify-between"
                        style={{
                            gap: spacingFluidXSmall,
                            marginTop: spacingFluidXSmall,
                        }}
                    >
                        {/* Botón para eliminar el menor */}
                        <PButton
                            icon="delete"
                            hideLabel={true}
                            onClick={() => handleRemoveMinor(index)}
                        />

                        {/* Contenedor para los controles de edad */}
                        <PTextFieldWrapper className="flex-grow">
                            <PText slot='label' size={'x-small'} >
                                {texts?.slotHead}:
                            </PText>
                            {/* <span slot="description">Test2</span> */}
                            {/* Campo de entrada para la edad */}
                            <input
                                type="number"
                                value={minor.age}
                                onChange={(e) => handleMinorAgeChange(index, e.target.value)}
                                onKeyDown={preventInvalidCharacters}
                                min="2"
                                max="17"
                                className="confirmationInput age-input"
                            />

                        </PTextFieldWrapper>
                        {/* Botón de decremento */}
                        <PButton
                            icon="subtract"
                            hideLabel={true}
                            onClick={() => decrementAge(index)}
                            disabled={parseInt(minor.age, 10) <= 2}
                            className="age-button"
                            aria-label={`${texts?.aria?.ageDecrease} ${index + 1}`}
                        />

                        {/* Botón de incremento */}
                        <PButton
                            icon="add"
                            hideLabel={true}
                            onClick={() => incrementAge(index)}
                            disabled={parseInt(minor.age, 10) >= 17}
                            className="age-button"
                            aria-label={`${texts?.aria?.ageIncrease} ${index + 1}`}
                        />

                        {/* Mensaje de validación */}
                        {/* {validationMessages[`minor-${index}`] && (
              <PText className="errorText">{validationMessages[`minor-${index}`]}</PText>
            )} */}
                    </div>
                ))}
            </div>

            {minors.length > 0 && (
                <div
                    style={{ marginTop: spacingFluidSmall }}
                >
                    <PButtonPure icon="add" onClick={handleAddMinor}>{texts?.add}</PButtonPure>
                </div>
            )}
        </div>
    );
};

export default MinorsSection;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BentoCard, BentoGrid } from "components/uiElements/UIComponents/bento-grid/bento-grid";
import { PSpinner } from '@porsche-design-system/components-react';
import './TourList.css';
import useGetUserAgent from 'mainApp/assets/utils/hooks/useGetUserAgent';
import BlurFade from '@/components/magicui/blur-fade';
import { combinedSkeletonStyles, fadeInStyles, GridContainer, scrollToTop, SkeletonBoxLight, skeletonKeyframes, SkeletonTourListPreload } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';
import { motionDurationLong, motionEasingBase } from '@porsche-design-system/components-js/styles';

function TourList({ onExit }) {

    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const deviceType = useGetUserAgent();

    const isPhone = deviceType === "PC" ? false : true;

    useEffect(() => {
        console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL); // Verify value

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/toursAPI/get-list`)
            .then(response => {
                console.log('API response:', response.data);
                if (response.data && Array.isArray(response.data.tours)) {
                    const transformedTours = response.data.tours.map(tour => ({
                        id: tour.tourUUID,
                        title: tour.name,
                        description: tour.description,
                        images: tour.cover_image_uri.startsWith('http://') || tour.cover_image_uri.startsWith('https://')
                            ? tour.cover_image_uri
                            : `${process.env.REACT_APP_API_BASE_URL}${tour.cover_image_uri}`,
                        price: tour.priceMin,
                        sprice: tour.stripe_price_id,
                        priceShown: `${tour.priceMin} per Tuktuk`,
                        duration: tour.duration
                    }));
                    setTours(transformedTours);
                } else {
                    console.error("API response does not contain an array of tours:", response.data);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the tours!", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after fetching data
            });
    }, []);

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    scrollToTop();
    return (
        <div className="bodySelectTourList"
        // style={bodySelectTourListStyling}
        >
            {loading ? (
                <div className='spinnerList'>
                    <PSpinner
                        style={fadeInStyles}
                    />
                </div>
            ) : (
                <>
                    {/* <BentoGrid className="lg:grid-rows-4 h-full"> */}
                    {!imageLoaded &&
                        <SkeletonTourListPreload />
                    }
                    <GridContainer isPhone={isPhone}>
                        {Array.isArray(tours) && tours.length > 0 ? (
                            tours.map((tour, index) => (
                                <BentoCard key={tour.id}
                                    tourState={tour}
                                    index={index}
                                    {...tour}
                                    onImagesLoad={handleImageLoad}
                                    imageLoaded={imageLoaded}
                                // callOnExit={onExit}
                                />
                            ))
                        ) : (
                            <p>No tours available.</p>
                        )}
                    </GridContainer>
                    {/* </BentoGrid> */}

                    {/* <BentoGrid className="lg:grid-rows-4 h-full">
                        {Array.isArray(tours) && tours.length > 0 ? (
                            tours.map((tour, index) => (
                                <BentoCard key={tour.id}
                                    tourState={tour}
                                    index={index}
                                    {...tour} />
                            ))
                        ) : (
                            <p>No tours available.</p>
                        )}
                    </BentoGrid> */}
                </>

            )}
        </div>

    );
}

export default TourList;

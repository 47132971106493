import { PDivider, PText } from "@porsche-design-system/components-react";
import "./TextWrapperPrice.css";
import { spacingFluidMedium, spacingFluidSmall, spacingFluidXSmall } from "@porsche-design-system/components-js/styles";

const TextWrapperPrice = ({ text, price, currency, isTotal, weight, className, noPadding, noMargin, isFullMargin, isDrawer }) => {

    const textWrapperPriceStyling = {
        color: isTotal ? "" : "#a09696",
        marginTop: noMargin ? "" : isTotal ? spacingFluidMedium : spacingFluidXSmall,
        margin: isFullMargin ? `0 ${spacingFluidMedium} 0 ${spacingFluidSmall}` : "",
        paddingBottom: noPadding ? "" :isTotal ? "10px" : "5px",
        // paddingBottom: isTotal ? "10px" : "16px",
    }
    const textWrapperPriceSizeStyling = {
        base: isTotal ? "large" : 'small',
        l: isTotal ? "large" : 'medium',
    }

    const drawerTextWrapperPriceSizeStyling = {
        base: isTotal ? "x-large" : 'small',
        l: isTotal ? "x-large" : 'medium',
    }

    return (
        <div
            className={className ? className : "textWrapperPrice"}
            style={textWrapperPriceStyling}
        >
            <PText size={isDrawer ? drawerTextWrapperPriceSizeStyling : textWrapperPriceSizeStyling}
                color="inherit"
                weight={weight}
            >
                {text}
            </PText>
            <div className="textWrapperPrice_price">
                <PText size={textWrapperPriceSizeStyling}
                    color="inherit"
                    weight={weight}
                >
                    {price}
                </PText>
                <PText size={textWrapperPriceSizeStyling}
                    color="inherit"
                    weight={weight}
                >
                    {currency}
                </PText>
            </div>
            <PDivider
                className="textWrapperPrice_divider"
            />
        </div>
    )
};

export default TextWrapperPrice;
import React, { useState } from "react";
import "./TourSelection.css";
import TourList from "../../../components/TourList";
import { useNavigate } from "react-router-dom";

const TourSelection = () => {

    //----
    // Posible componente para detectar aspect ratio y adaptar su distribución
    //----
    const [isExiting, setIsExiting] = useState(false);
    const navigate = useNavigate();


    // const handleExit = (path) => {
    //     setIsExiting(true); // Inicia la animación de salida
    //     setTimeout(() => {
    //         navigate(path); // Navega a la nueva página después de la animación
    //     }, 500); // Asegúrate de que coincida con la duración de la animación en ms
    // };

    return (
        <div className={`tourSelection ${isExiting ? 'fade-out' : ''}`}>
        {/* <div className={`parent-compon ${isExiting ? 'fade-out' : ''}`}> */}

            {/* <div className="bodySelectTourList"> */}
            {/* <BorderedContainer 
            style={borderedContainerStyling}
            > */}
            <TourList
            //  onExit={handleExit} 
             />
            {/* </BorderedContainer> */}
            {/* </div> */}
            {/* <BentoBuilder /> */}
            {/* Boxes / Grid Bento */}
            {/* Titles */}
            {/* Price */}
            {/* Time */}
            {/* Images */}
            {/*  */}
            {/* FRAGMENT SELECT */}
            {/*  */}
        </div>
    );
}

export default TourSelection;
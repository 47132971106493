import React, { useState, useCallback, useEffect } from 'react';
import {
  PButton,
  PButtonPure,
  PSegmentedControl,
  PSegmentedControlItem,
  PSpinner,
  PText,
} from '@porsche-design-system/components-react';
import './TimeSelection.css';
import { DateTime } from 'luxon';
import axios from 'axios';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';
import { combinedStyle, tileBaseStyleDrop, tileBaseStyleGrad } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';
import { gradientToBottomStyle, spacingFluidLarge, spacingFluidMedium, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';
import BlurFade from '@/components/magicui/blur-fade';
import axiosInstance from 'axiosConfig';

const TimeSelection = ({ date, onTimeSelect, onBack, isPhone, tourUUID }) => {
  const { texts } = useTexts('timeSelection');

  // Definir el horario de apertura y cierre
  const OPENING_HOUR = 10; // 10:00
  const CLOSING_HOUR = 19; // 19:00

  // Generar dinámicamente las horas disponibles dentro del rango permitido
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0') + ':00';
    return { label: hour, hour: i };
  }).filter(timeObj => timeObj.hour >= OPENING_HOUR && timeObj.hour <= CLOSING_HOUR)
    .map(timeObj => timeObj.label);

  const [currentValue, setCurrentValue] = useState(null);
  const [unavailableTimes, setUnavailableTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUnavailableTimes = async (date) => {
    setLoading(true);
    try {
      const formattedDate = DateTime.fromSeconds(date).toFormat('yyyy-MM-dd');
      const response = await axiosInstance.get(`api/toursAPI/${tourUUID}/get-unavailable-hours`, {
        params: {
          date: formattedDate,
          timezone: 'Europe/Madrid'
        }
      });
      console.log("response hour", response.data);
      setUnavailableTimes(response.data); // Asumiendo que response.data es un array de cadenas como ['12:00', '14:00']
    } catch (error) {
      console.error('Error fetching unavailable times', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUnavailableTimes(date);
  }, [date, tourUUID]); // Asegúrate de incluir tourUUID en las dependencias

  const onUpdate = useCallback((e) => {
    setCurrentValue(e.detail.value);
  }, []);

  const handleTimeSelect = () => {
    if (currentValue !== null) {
      onTimeSelect(times[currentValue]);
    }
  };

  return (
    <BlurFade className="fadeInCalendar">
      <div className="time-selection">
        <PButtonPure
          className="back-button"
          icon="arrow-left"
          hideLabel={true}
          onClick={onBack}
          style={{ marginLeft: isPhone ? "" : spacingFluidXSmall }}
        />
        {loading ? (
          <PSpinner className='spinnerTimeSel' />
        ) : (
          <div className="time-selection-sticky" style={{ paddingTop: spacingFluidLarge }}>
            <BlurFade className="flex w-full justify-center flex-col items-center">
              <div className="segmented-control-container" style={tileBaseStyleDrop}>
                <h1 className='timeSelHeadText'>{texts?.head}</h1>
                <BlurFade>
                  <PSegmentedControl
                    value={currentValue}
                    onUpdate={onUpdate}
                    className='pSegmentedControlStyle'
                  >
                    {times.map((time, index) => (
                      <PSegmentedControlItem
                        key={time}
                        value={index}
                        disabled={unavailableTimes.includes(time)}
                        className='pSegmentedControlItemStyle'
                      >
                        {time}
                      </PSegmentedControlItem>
                    ))}
                  </PSegmentedControl>
                </BlurFade>
              </div>
              <PButton
                className="select-time-button"
                onClick={handleTimeSelect}
                disabled={currentValue === null}
              >
                {texts?.confirmButton}
              </PButton>
            </BlurFade>
            {currentValue !== null && (
              <PText>
                {texts?.content} {times[currentValue]}
              </PText>
            )}
          </div>
        )}
      </div>
    </BlurFade>
  );
};

export default TimeSelection;

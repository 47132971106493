import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './CancelScreen.module.css';

const CancelScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Verifica si hay una URL de origen en el estado o en los parámetros de la URL
    const searchParams = new URLSearchParams(location.search);
    const originUrl = searchParams.get('origin_url') || '/';

    // Redirige a la URL de origen o a la página principal después de 5 segundos
    const timer = setTimeout(() => {
      navigate(originUrl);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate, location]);

  return (
    <div className={styles.cancelScreen}>
      <h1>Pago Cancelado</h1>
      <p>Has cancelado el proceso de pago. Serás redirigido en breve...</p>
      <p>Si no eres redirigido automáticamente, haz clic <a href="/">aquí</a> para regresar a la página principal.</p>
    </div>
  );
};

export default CancelScreen;

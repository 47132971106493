import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SmoothSlider.css'; // Asegúrate de tener este archivo CSS para estilos personalizados
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { PCarousel, PSpinner, PText } from '@porsche-design-system/components-react';
import { borderRadiusLarge, dropShadowHighStyle, dropShadowLowStyle, dropShadowMediumStyle, textSmallStyle, themeLightBackgroundSurface, themeLightPrimary } from '@porsche-design-system/components-js/styles';
import { IsRotatedContext } from 'mainApp/assets/utils/context/isRotatedContext';
import useIsPhone from 'mainApp/assets/utils/hooks/useIsPhone';

const SmoothSlider = ({ images }) => {
    if (!images) {
        images = []
    }
    // console.log(images);
    const [formattedUrls, setFormattedUrls] = useState([]);
    const [imageLoading, setImageLoading] = useState(new Array(images.length).fill(true)); // Estado para rastrear la carga de cada imagen

    useEffect(() => {
        const formatUrls = () => {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const newUrls = images.map(url => {
                if (url.startsWith('http://') || url.startsWith('https://')) {
                    return url;
                } else {
                    return `${baseUrl}${url}`;
                }
            });
            setFormattedUrls(newUrls);
        };

        formatUrls();
    }, [images]);

    const handleImageLoad = (index) => {
        setImageLoading((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[index] = false; // Marca la imagen como cargada
            return newLoading;
        });
    };
    const { isRotated } = useContext(IsRotatedContext);
    const isPhone = useIsPhone();
    const rotatedStyle = {
        height: isPhone ? isRotated ? "32.5svw" : "32.5svh" : "32.5svh",
    };


    return (
        <>

            <PCarousel
                // activeSlideIndex={true}
                // rewind={true}
                pagination={true}
                slidesPerPage="auto"
                width="extended"
            >
                {formattedUrls.map((image, index) => (
                    <div
                        className="newCard"
                        key={index}
                    >
                        {imageLoading[index] && (
                            <PSpinner className='pspinnerSmoothSlider' />
                        )}
                        <img
                            className="newCardImage"
                            style={rotatedStyle}
                            src={image}
                            alt={`image-${index}`}
                            onLoad={() => handleImageLoad(index)}
                        />
                        {/* Puedes descomentar estas líneas si necesitas mostrar título o descripción */}
                        <div className="card-title">
                            <PText
                                color='inherit'
                            >Venus (Testing Data)</PText></div>
                        {/* <div className="card-title">{image.title}</div> */}
                        {/* <div className="card-info self-end">
                            <PText
                                color='white'
                            >Aquí puedes poner cualquier cosa</PText></div> */}
                        {/* <div className="card-info">{image.description}</div> */}
                    </div>
                ))}

                {/* Añadir un div extra solo después del último elemento */}
                {formattedUrls.length > 0 && (
                    <div className="extraDiv">
                        {/* Contenido del div extra */}
                    </div>
                )}
            </PCarousel>
            {/* </div> */}
        </>






        // <div className="slider-container bg-rose-500"
        //     style={{
        //         maxWidth: '100vw'
        //     }}>
        //     <button className="slider-button prev" onClick={() => sliderRef.current.slickPrev()}>
        //         <ChevronLeftIcon className="mr-1 h-4 w-4 transition-transform duration-300 group-hover:-translate-x-1" />
        //     </button>
        //     <button className="slider-button next" onClick={() => sliderRef.current.slickNext()}>
        //         <ChevronRightIcon className="ml-1 h-4 w-4 transition-transform duration-300 group-hover:-translate-x-1" />
        //     </button>
        //     <Slider ref={sliderRef} {...settings}
        //     //  classname="sliderStyle"
        //      >
        //         {formattedUrls.map((image, index) => (
        //             <div className="card" key={index}
        //                 style={dropShadowLowStyle}
        //             >
        //                 {imageLoading[index] && (

        //                     <PSpinner className='pspinnerSmoothSlider' />

        //                 )}
        //                 <img
        //                     src={image}
        //                     alt={`image-${index}`}
        //                     onLoad={() => handleImageLoad(index)} // Manejador onLoad para cada imagen
        //                     // style={
        //                     //     tileBaseStyleDrop
        //                     // }
        //                 />
        //                 {/* <div className="card-title">{image.title}</div> */}
        //                 {/* <div className="card-info">{image.description}</div> */}
        //             </div>
        //         ))}
        //     </Slider>
        // </div>
    );
};

export default SmoothSlider;

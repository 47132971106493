import React, { useContext, useEffect, useState } from 'react';
import './CallToBook.css';
import axiosInstance from 'axiosConfig';
import { Link } from "react-router-dom";
import { PButton, PSpinner, PText } from '@porsche-design-system/components-react';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';
import { dropShadowLowStyle, spacingFluidLarge, spacingFluidSmall, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';
import { BorderBeam } from '@/components/magicui/border-beam';
import { tileBaseStyleDrop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';
import VitalInfoComponent from 'components/VitalInfoComponent/VitalInfoComponent';
import styled from 'styled-components';
import {
    getSkeletonStyle,
    fontLineHeight,
    fontSizeTextLarge,
    fontSizeTextMedium,
    fontSizeTextSmall,
    gridGap,
    headingMediumStyle,
    spacingFluidMedium,
    themeDarkBackgroundBase,
    themeDarkPrimary,
    themeLightBackgroundBase,
    themeLightPrimary,
} from '@porsche-design-system/components-react/styles';
import { IsRotatedContext } from 'mainApp/assets/utils/context/isRotatedContext';

// Definición de Skeletons
const SkeletonLight = styled.div(getSkeletonStyle({ theme: 'light' }));
const SkeletonTextLight = styled(SkeletonLight)({
    height: fontLineHeight,
    width: '60%',
    fontSize: fontSizeTextMedium,
    // marginBottom: '1rem',
});

const SkeletonDark = styled.div(getSkeletonStyle({ theme: 'dark' }));
const SkeletonTextDark = styled(SkeletonDark)({
    height: fontLineHeight,
    width: '60%',
    fontSize: fontSizeTextMedium,
    // marginBottom: '1rem',
});

const CallToBook = ({ tour, isSimple, isInfo, isPhone }) => {
    const { isRotated } = useContext(IsRotatedContext);

    const [nextTour, setNextTour] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { texts, isLoading: textsLoading, error: textsError } = useTexts('callToBook');

    const cacheKey = `nextTour_${tour?.id}`;

    const fetchNextTour = async () => {
        try {
            if (!tour || !tour.id) {
                throw new Error("No tour data available");
            }

            const cachedNextTour = localStorage.getItem(cacheKey);
            const now = Date.now();

            if (cachedNextTour) {
                const cachedData = JSON.parse(cachedNextTour);
                const { nextTourEpoch } = cachedData;

                if (nextTourEpoch > now) {
                    setNextTour(nextTourEpoch);
                    const initialTimeRemaining = calculateTimeRemaining(nextTourEpoch);
                    setTimeRemaining(initialTimeRemaining);
                    setLoading(false);
                    return;
                }
            }

            const tourUUID = tour.id;
            const response = await axiosInstance.get(`api/toursAPI/${tourUUID}/next-available-slot`);

            if (!response.data || !response.data.next_available_slot) {
                throw new Error("No next available slot found");
            }

            const newNextTour = response.data.next_available_slot * 1000;
            setNextTour(newNextTour);

            // Cache the new next tour value
            localStorage.setItem(cacheKey, JSON.stringify({ nextTourEpoch: newNextTour }));

            // Calculate the time remaining
            const initialTimeRemaining = calculateTimeRemaining(newNextTour);
            setTimeRemaining(initialTimeRemaining);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching next tour:", error);
            setError(error.message);
            setLoading(false);
        }
    };

    const handleCountdownEnd = () => {
        setLoading(true);
        setTimeRemaining(null);
        fetchNextTour();
    };

    useEffect(() => {
        if (isFirst) {
            fetchNextTour();
            setIsFirst(false);
        }

        const interval = setInterval(() => {
            if (nextTour) {
                const newTimeRemaining = calculateTimeRemaining(nextTour);
                setTimeRemaining(newTimeRemaining);

                if (newTimeRemaining.total <= 0) {
                    clearInterval(interval);
                    handleCountdownEnd();
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [nextTour]);

    const calculateTimeRemaining = (tourDateEpoch) => {
        const now = Date.now();
        const timeDifference = tourDateEpoch - now;

        if (timeDifference > 0) {
            const total = timeDifference;

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            return { total, days, hours, minutes, seconds };
        } else {
            return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const transformString = (str) => {
        return str ? str.toLowerCase().replace(/ /g, '-') : '';
    };

    const dashTitle = tour && tour.title ? transformString(tour.title) : '';

    const countdownStyle = {
        marginTop: isSimple ? spacingFluidXSmall : '0px',
    };
    const internalCountdownStyle = {
        size: "x-large",
        align: "center",
    };
    const tourReservationStyling = {
        position: isPhone ? "relative" : "sticky",
        top: isRotated ? "2.5svw" : "2.5svh",

        // marginBottom: "7.75%",
        padding: spacingFluidMedium,
        // margin: spacingFluidMedium,
        margin: isPhone ? `0 ${spacingFluidLarge} ${spacingFluidMedium} ${spacingFluidLarge}` : `${spacingFluidSmall} ${spacingFluidMedium} 0 ${spacingFluidMedium}`,
        // margin: `${spacingFluidSmall} ${spacingFluidMedium} ${spacingFluidMedium} ${spacingFluidMedium}`,

    };

    // Determina si debe mostrar el Skeleton
    const isLoading = loading || textsLoading;

    return (
        <>
            <div
                className="tour-reservation relative"
                style={{
                    ...tileBaseStyleDrop,
                    ...dropShadowLowStyle,
                    ...tourReservationStyling,
                }}
            >
                {isSimple ? null : <BorderBeam borderRadius="1rem" className="tour-reservation" />}

                {/* Mostrar Skeleton mientras se cargan los textos o los datos del tour */}
                {isLoading ? (
                    <div>
                        {/* Skeleton para el título */}
                        <SkeletonTextLight style={{ width: '60%' }} />
                        {/* Skeleton para el contenido */}
                        <SkeletonTextLight style={{
                            width: '80%',
                            // marginTop: '1rem'
                        }} />
                    </div>
                ) : textsError ? (
                    <div className="error-message">
                        {textsError || "Ocurrió un error al cargar los textos."}
                    </div>
                ) : (
                    <>
                        {/* <h2>{texts?.head || "Cargando"}</h2> */}
                        <PText
                            // weight='regular'
                            size="large"
                            align='center'
                        // size="small"
                        >{texts?.head || "Cargando"}</PText>

                        {loading ? (
                            <div>
                                <PSpinner />
                            </div>
                        ) : error ? (
                            <div className="error-message">
                                {error || "Ocurrió un error al obtener el siguiente tour."}
                            </div>
                        ) : (
                            <div className="countdown-timer flex flex-row justify-center" style={{ ...countdownStyle, }}>
                                {timeRemaining.days > 0 &&
                                    <PText
                                        size={internalCountdownStyle.size}
                                        align={internalCountdownStyle.align}
                                    >{timeRemaining.days}d&nbsp;</PText>
                                    // <span>{timeRemaining.days}d </span>
                                }
                                {timeRemaining.hours > 0 &&
                                    <PText
                                        size={internalCountdownStyle.size}
                                        align={internalCountdownStyle.align}
                                    >{timeRemaining.hours}h&nbsp;</PText>
                                    // <span>{timeRemaining.hours}h </span>
                                }
                                <PText
                                    size={internalCountdownStyle.size}
                                    align={internalCountdownStyle.align}
                                >{timeRemaining.minutes}m {timeRemaining.seconds}s </PText>
                                {/* <span>{timeRemaining.minutes}m </span>
                                <span>{timeRemaining.seconds}s </span> */}
                            </div>
                        )}

                        {isSimple ? (
                            <div
                                style={{ marginTop: spacingFluidMedium }}
                            // className="z-10 flex mt-[20px] items-center justify-center"
                            >
                                <Link to={`/reservation/${dashTitle}`} state={{ tour }}>
                                    <PButton theme="light" className="relative">
                                        {texts?.content}
                                    </PButton>
                                </Link>
                            </div>
                        ) : (
                            <Link to={`/reservation/${dashTitle}`} state={{ tour }}>
                                <button className="reserve-button">
                                    Reserve Yours Now!
                                </button>
                            </Link>
                        )}
                    </>
                )}
            </div>

            {isInfo ? (
                <VitalInfoComponent tour={tour} className="vitalInfoComponentOnCallToAction" />
            ) : (
                ''
            )}
        </>
    );
};

export default CallToBook;

import { createContext, useState } from "react";

export const IsRotatedContext = createContext({
    isRotated: false,
    setIsRotated: () => { }
});
export const IsRotatedProvider = ({ children }) => {

    const [isRotated, setIsRotated] = useState(0);
    return (
        <IsRotatedContext.Provider
            value={{
                isRotated,
                setIsRotated
            }}
        >
            {children}
        </IsRotatedContext.Provider>
    );
}
import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`, 
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//   },
// });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // Si tu API necesita enviar cookies
  headers: {
    'Content-Type': 'application/json',
    // Agrega otros encabezados si es necesario
  }
});


// Añadir un interceptor para incluir el token de autenticación en cada solicitud si está presente
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;

// RenderStaticData.js
import React from 'react';
import { PDivider, PButton, PText } from '@porsche-design-system/components-react';
import './RenderStaticData.css';
import TextWrapperPrice from 'components/TextWrapperPrice/TextWrapperPrice';
import { dropShadowLowStyle, spacingFluidLarge, spacingFluidMedium, spacingFluidSmall, spacingFluidXLarge, spacingFluidXSmall, spacingFluidXXLarge, textLargeStyle } from '@porsche-design-system/components-js/styles';
import TextWrapperGeneral from 'components/TextWrapperGeneral/TextWrapperGeneral';
import { tileBaseStyleDrop } from 'mainApp/assets/visual/styles/globalStyles/globalJsxStyles';

const RenderStaticData = ({
    time,
    date,
    totalPrice,
    formData,
    isFormIncompleteProp,
    handleConfirmAndSetAppointment,
    texts,
    titleStyle,
    currency,
    isPhone,
}) => {
    // const currency = "€";

    return (
        // <div className='bg-rose-500 h-full'>
        <div className='testCol'
            style={{
                gap: spacingFluidXLarge,
                padding: spacingFluidMedium,
                justifyContent: isPhone ? "space-between" : "",
                ...tileBaseStyleDrop,
                ...dropShadowLowStyle,
            }}
        >
            <div className='flex flex-col'
                style={{ gap: isPhone ? spacingFluidXLarge : spacingFluidMedium }}
            >
                <PText
                    weight='bold'
                    size="x-large"
                >
                    {texts?.resume?.head}
                </PText>
                {/* <h1 className="confirmationTitle" style={titleStyle}>
                {texts?.resume?.head}
            </h1> */}
                <div className='flex flex-col'
                    style={{ gap: spacingFluidSmall }}
                >
                    <div className='flex flex-col'
                        style={{ gap: spacingFluidXSmall }}
                    // style={{ gap: spacingFluidSmall }}
                    >
                        <TextWrapperGeneral
                            className="confirmationDetail"
                            text={`${texts?.resume?.startHour}: `}
                            value={time}
                        />
                        <TextWrapperGeneral
                            className="confirmationDetail"
                            text={`${texts?.resume?.date}: `}
                            value={new Date(date).toLocaleDateString('es-ES')}
                        />
                    </div>

                    <TextWrapperGeneral
                        text={`${texts?.resume?.vehicle} x${formData?.totalUnits}:`}
                        value={totalPrice}
                        currency={currency}
                        pcolor="neutral-contrast-medium"
                        size="medium"
                    />
                </div>
            </div>
            <div className='flex flex-col'
                style={{ gap: isPhone ? spacingFluidMedium : spacingFluidSmall }}
            >
                <TextWrapperPrice
                    text={`${texts?.resume?.totalPrice}:`}
                    price={totalPrice}
                    currency={currency}
                    isTotal={true}
                    weight='bold'
                />
                <PButton
                    className="confirmButton"
                    onClick={handleConfirmAndSetAppointment}
                    disabled={isFormIncompleteProp}
                    style={{ marginTop: spacingFluidMedium }}
                >
                    {texts?.resume?.confirmButton}
                </PButton>
            </div>
        </div>
        // </div>
    );
};

export default RenderStaticData;

import useGetUserAgent from "./useGetUserAgent";

const useIsPhone = () => {
    const device = useGetUserAgent();

if (device === "PC")
{
    return false;
}
    return true;
}

export default useIsPhone;
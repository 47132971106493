import { PDivider, PText } from "@porsche-design-system/components-react";
import "./TextWrapperGeneral.css";

const TextWrapperGeneral = ({ text, value, className, currency, pcolor, size }) => {

    // const textWrapperPriceStyling = {
    //     color: isTotal ? "" : "#a09696",
    //     marginTop: isTotal ? "16px" : "16px",
    //     paddingBottom: isTotal ? "16px" : "10px",
    //     // paddingBottom: isTotal ? "10px" : "16px",
    // }
    const textWrapperGeneralSizeStyling = {
        base: size ? size : "large",
        l: size ? size : "large",
    }

    return (
        <div
            className={`textWrapperGeneral ${className}`}
        // style={textWrapperPriceStyling}
        >
            <PText
                size={textWrapperGeneralSizeStyling}
                color={pcolor ? pcolor : `inherit`}
            >
                {text}
            </PText>
            <PText
                size={textWrapperGeneralSizeStyling}
                color={pcolor ? pcolor : `inherit`}
            >
                {value}
                {currency}
            </PText>

            <PDivider
                className="textWrapperGeneral_divider"
            />
        </div>
    )
};

export default TextWrapperGeneral;
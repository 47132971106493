import { dropShadowLowStyle, spacingFluidMedium, spacingFluidSmall, spacingFluidXSmall } from "@porsche-design-system/components-js/styles";
import { UserRound } from "lucide-react";
import useTexts from "mainApp/assets/utils/hooks/useTexts";
import { tileBaseStyleDrop } from "mainApp/assets/visual/styles/globalStyles/globalJsxStyles";
import { ReactComponent as TuktukSVG } from '../../mainApp/assets/visual/vectors/UI/logos/Tuktuk.svg';
import { ReactComponent as UserSVG } from '../../mainApp/assets/visual/vectors/UI/logos/User.svg';
import React, { useContext } from "react";
import './VitalInfoComponent.css';
import useIsPhone from "mainApp/assets/utils/hooks/useIsPhone";
import { PText } from "@porsche-design-system/components-react";
import { IsRotatedContext } from "mainApp/assets/utils/context/isRotatedContext";

const VitalInfoComponent = ({ tour, isMulti, className }) => {
    const { texts } = useTexts('tourInfo');
    const { isRotated } = useContext(IsRotatedContext);
    const isPhone = useIsPhone();

    const vitalInfoStyling = {
        height: isPhone ? "100%" : "",
        // minHeight: "7vh",
        padding: `0  ${spacingFluidXSmall}  ${spacingFluidXSmall} ${spacingFluidXSmall}`

    };


    return (

        <div className="vitalInfo"
            style={vitalInfoStyling}
        >
            <div
                style=
                {
                    {
                        minHeight: isPhone ? isRotated ? "16vh" : "8vh" : isRotated ? "8vh" : "3vh",
                        ...tileBaseStyleDrop,
                        ...dropShadowLowStyle,
                        padding: spacingFluidSmall,
                    }
                }
            >
                <PText
                    weight="bold"
                >
                    {texts?.price_text || "Loading..."}
                </PText>
                <PText
                    className="flex justify-center"
                    size="medium"
                >
                    {tour.price}€
                </PText>
            </div>

            <div
                className="vehicleInfo"
                style=
                {
                    {
                        minHeight: isPhone ? isRotated ? "16vh" : "8vh" : isRotated ? "8vh" : "3vh",
                        ...tileBaseStyleDrop,
                        ...dropShadowLowStyle,
                        padding: spacingFluidXSmall,
                    }
                }
            >
                <TuktukSVG className="tuktukSVG" />
                <PText>&nbsp;&nbsp;=&nbsp;</PText>
                <UserSVG className="personSVG" />
                <PText>4</PText>
            </div>
        </div>
    );
};

export default VitalInfoComponent;
import { useCallback, useState } from 'react';
import { PModal, PButton, PText, PButtonGroup, PHeading } from '@porsche-design-system/components-react';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';
import { SkeletonComponent } from 'components/SkeletonComponent/SkeletonComponent';

export const ClosingModal = ({ accept, onDismiss, questionText, descriptionText }) => {
    const { texts, isLoading } = useTexts("leaving"); // Asumiendo que useTexts también retorna un estado de carga

    // Puedes ajustar la condición según cómo tu hook gestione el estado de carga
    const isTextsLoaded = texts && !isLoading;

    return (
        <>
            {isTextsLoaded ? (
                <PModal open={true} onDismiss={onDismiss} aria={{ 'aria-label': 'Some Heading' }}>
                    <PHeading slot="header" size="large" tag="h2">
                        {questionText || texts?.modal?.head}
                    </PHeading>
                    <PText>
                        {descriptionText || texts?.modal?.line}
                    </PText>
                    <PButtonGroup slot="footer">
                        <PButton type="button" onClick={accept}>
                            {texts?.modal?.button?.accept}
                        </PButton>
                        <PButton type="button" variant="secondary" onClick={onDismiss}>
                            {texts?.modal?.button?.decline}
                        </PButton>
                    </PButtonGroup>
                </PModal>
            ) : (
                <SkeletonComponent />
            )}
        </>
    );
};

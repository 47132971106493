import styled, { createGlobalStyle } from 'styled-components';
import {
  gridGap,
  spacingFluidLarge,
  spacingFluidMedium,
  spacingFluidSmall,
  spacingFluidXXLarge,
  spacingFluidXLarge,
  spacingFluidXSmall,

  borderRadiusLarge,
  fontLineHeight,
  getSkeletonStyle,
  gradientToBottomStyle,
  motionDurationModerate,
  motionDurationShort,
  motionEasingIn,
  motionEasingOut,
  textSmallStyle,
  themeDarkPrimary,
  themeLightBackgroundBase,
  themeLightBackgroundSurface,
  themeLightPrimary,
  getMediaQueryMin,
  getMediaQueryMax,
  breakpointXS,
  breakpointS,
  breakpointM,
  breakpointL,
  breakpointXL,
  breakpointXXL,
  motionEasingBase,

} from '@porsche-design-system/components-react/styles';
import { useEffect, useRef, useState } from 'react';

// Define el estilo del título utilizando media queries


export const tileBaseStyleDrop = {
  ...textSmallStyle,
  color: themeLightPrimary,
  background: themeLightBackgroundSurface,
  // padding: spacingFluidSmall,
  borderRadius: borderRadiusLarge,
};

const Wrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: gridGap,
  padding: spacingFluidMedium,
});

const WrapperLight = styled(Wrapper)({
  background: themeLightBackgroundBase,
  color: themeLightPrimary,
});

// Skeletons shared
const SkeletonLight = styled.div(getSkeletonStyle({ theme: 'light' }));

// Skeletons box
export const boxStyles = {
  height: '200px',
  width: '500px',
};

export const SkeletonBoxLight = styled(SkeletonLight)(boxStyles);

const textStyles = {
  height: fontLineHeight,
  width: '100px',
};

export const SkeletonUnifiedLight = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: gridGap,
  padding: spacingFluidMedium,
  background: themeLightBackgroundBase,
  color: themeLightPrimary,
}

export const skeletonStyleLight = {
  display: 'block',
  background: '#EEEFF2',
  backgroundImage: 'linear-gradient(to right, transparent 0%, #f7f7f7 25%, transparent 50%)',
  backgroundPosition: '0 0',
  // backgroundSize: '200% 100%',
  borderRadius: '4px',
  animation: 'skeletonAnimation 0.6s cubic-bezier(0.25, 0.1, 0.25, 1) infinite',
  '@keyframes skeletonAnimation': {
    from: {
      backgroundPositionX: '100%',
    },
    to: {
      backgroundPositionX: '-100%',
    },
  },
};


export const combinedSkeletonStyles = {
  // Estilos de WrapperLight
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: gridGap,
  padding: spacingFluidMedium,
  background: themeLightBackgroundBase,
  color: themeLightPrimary,

  // Estilos de SkeletonLight
  ...getSkeletonStyle({ theme: 'light' }),

};

export const skeletonKeyframes = `
@keyframes skeletonAnimation {
  from {
    background-position-x: 100%;
  }
  to {
    background-position-x: -100%;
  }
}`;


export const getMotionEnterExitStyles = (active) => {
  const baseStyles = {
    transform: 'translateY(0px)',
    transition: `opacity ${motionDurationModerate} ${motionEasingIn}, transform ${motionDurationModerate} ${motionEasingIn}`,
  };

  if (active) {
    return {
      ...baseStyles,
      transform: 'translateY(40%)',
      transition: `opacity ${motionDurationShort} ${motionEasingOut}, transform ${motionDurationShort} ${motionEasingOut}`,
      opacity: '0',
    };
  }

  return baseStyles;
};

export const TourTitle = ({ title }) => {
  const [active, setActive] = useState(true);
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setActive(!entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  const styles = getMotionEnterExitStyles(active);

  return (
    <h1 className="textTitleContent" ref={titleRef} style={styles}>
      {title}
    </h1>
  );
};

const WrapperGrad = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: gridGap,
  padding: spacingFluidMedium,

});

// Tile
export const tileBaseStyleGrad = {
  ...textSmallStyle,
  color: themeDarkPrimary,
  borderRadius: borderRadiusLarge,
  padding: spacingFluidMedium,
};

export const combinedStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: gridGap,
  padding: spacingFluidMedium,
  ...textSmallStyle,
  color: themeDarkPrimary,
  borderRadius: borderRadiusLarge,
  ...gradientToBottomStyle,
};

// Define los estilos del fade-in
export const fadeInStyles = {
  // opacity: 0,
  animation: 'fadeInAnimation 0.6s cubic-bezier(0.25, 0.1, 0.25, 1) ',
};

// Define los keyframes de la animación
const fadeInKeyframes = `
    @keyframes fadeInAnimation {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

// Inserta los keyframes en la hoja de estilos del documento
export const insertFadeInKeyframes = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(fadeInKeyframes, styleSheet.cssRules.length);
};

//   insertFadeInKeyframes(); on App.js or index.js

// On the component:
// useEffect(() => {
//     insertFadeInKeyframes(); // Inserta los keyframes al montar el componente
//   }, []);

export const TitleMedia = styled.h1`
  ${textSmallStyle}; // Aplica el estilo base de la fuente
  // ${fadeInStyles};   // Aplica el efecto de fade-in

  // Asegura que el texto se ajuste en pantallas más pequeñas
  white-space: nowrap; // Previene que el texto se desborde a nuevas líneas
  overflow: hidden;    // Oculta el texto desbordado
  text-overflow: ellipsis; // Muestra "..." al final del texto si se corta
  font-size: 2.5rem; // Ajusta el tamaño para pantallas XXL y menores

  // Ajusta el tamaño de la fuente según el tamaño de la pantalla
  ${getMediaQueryMax('xxl')} {
    font-size: 2.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }

  ${getMediaQueryMax('xl')} {
    font-size: 2rem; // Ajusta el tamaño para pantallas XL y menores
  }

  ${getMediaQueryMax('l')} {
    font-size: 1.6rem; // Ajusta el tamaño para pantallas L y menores
  }

  ${getMediaQueryMax('m')} {
    font-size: 1.6rem; // Ajusta el tamaño para pantallas M y menores
    // max-width: 15ch;   // Limita el ancho del texto a aproximadamente 50 caracteres
    // word-wrap: break-word; // Permite que las palabras se rompan y pasen a la siguiente línea
    // overflow-wrap: break-word; // Maneja el desbordamiento de palabras
    // white-space: wrap;
  }

  ${getMediaQueryMax('s')} {
    font-size: 1.7rem; // Ajusta el tamaño para pantallas S y menores
    max-width: 12ch;   // Limita el ancho del texto a aproximadamente 50 caracteres
    word-wrap: break-word; // Permite que las palabras se rompan y pasen a la siguiente línea
    overflow-wrap: break-word; // Maneja el desbordamiento de palabras
    white-space: wrap;
  }

  // Check if needed
  ${getMediaQueryMax('xs')} {
    font-size: 1.5rem; // Ajusta el tamaño para pantallas XS y menores
    max-width: 20ch;   // Limita el ancho del texto a aproximadamente 50 caracteres
    word-wrap: break-word; // Permite que las palabras se rompan y pasen a la siguiente línea
    overflow-wrap: break-word; // Maneja el desbordamiento de palabras
    white-space: wrap;
    // background-color: red;
    
  }

  max-width: 100%;

  animation: fadeInBackButton .25s linear forwards;

  @keyframes fadeInBackButton {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
`;

export const DefaultTitleMedia = styled.h1`
  ${fadeInStyles};   // Aplica el efecto de fade-in

  // Asegura que el texto se ajuste en pantallas más pequeñas
  white-space: nowrap; // Previene que el texto se desborde a nuevas líneas
  overflow: hidden;    // Oculta el texto desbordado
    font: 700 clamp(1.42rem, 1.23rem + 0.94vw, 2.37rem) / calc(2.125ex + 6px) "Porsche Next", 
    "Arial Narrow", Arial, "Heiti SC", SimHei, sans-serif;
  font-size: 1.5rem;
  ${getMediaQueryMax('xxl')} {
  font-size: 3.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }

  ${getMediaQueryMax('xl')} {
  font-size: 3.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }
  
  ${getMediaQueryMax('l')} {
  font-size: 2.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }

    ${getMediaQueryMax('m')} {
  font-size: 2.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }

  ${getMediaQueryMax('s')} {
    font-size: 1.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }

  ${getMediaQueryMax('xs')} {
  font-size: 1.5rem; // Ajusta el tamaño para pantallas XXL y menores
  }
  max-width: 100%;
  animation: fadeInBackButton .25s linear forwards;

  @keyframes fadeInBackButton {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;

  ${getMediaQueryMax('xxl')} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: clamp(8px, 0.5vw + 6px, 16px);

  }

  ${getMediaQueryMax('xl')} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: clamp(8px, 0.5vw + 6px, 16px);
  }

  ${getMediaQueryMax('l')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: clamp(32px, 2.75vw + 23px, 76px);
    
  }

  ${getMediaQueryMax('m')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: clamp(16px, 1.25vw + 12px, 36px);
  }

  ${getMediaQueryMax('s')} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: clamp(8px, 0.5vw + 6px, 16px);
  }

  ${getMediaQueryMax('xs')} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: clamp(4px, 0.25vw + 3px, 8px);
    gap: clamp(8px, 0.5vw + 6px, 16px);

  }
`;


// const headSelectStyling = {
//   marginBottom: isPhone ? "0" : "14vh",
// }


export const HeadSelect = styled.div`
    position: absolute;
    top: 0;
    // background-color: white;
    // background-color: rgb(83, 179, 51);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

  
//   ${getMediaQueryMax('xxl')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(8px, 0.5vw + 6px, 16px)' : ''};
//     gap: clamp(8px, 0.5vw + 6px, 16px);
//   }

//   ${getMediaQueryMax('xl')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(8px, 0.5vw + 6px, 16px)' : ''};
//     gap: clamp(8px, 0.5vw + 6px, 16px);
//   }
//   ${getMediaQueryMax('l')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(32px, 2.75vw + 23px, 76px)' : ''};
//     gap: clamp(32px, 2.75vw + 23px, 76px); 
//   }

//   ${getMediaQueryMax('m')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(16px, 1.25vw + 12px, 36px)' : ''};
//     gap: clamp(16px, 1.25vw + 12px, 36px);
//   }

//   ${getMediaQueryMax('s')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(8px, 0.5vw + 6px, 16px)' : ''};
//     gap: clamp(8px, 0.5vw + 6px, 16px);
//   }

//   ${getMediaQueryMax('xs')} {
//     // margin-bottom: ${props => props.isPhone ? 'clamp(4px, 0.25vw + 3px, 8px)' : ''};
//     gap: clamp(4px, 0.25vw + 3px, 8px);
// }
`;

export const SkeletonTourListPreload = styled.div`
/////////////////
// skeletonTourListPreload
  height: 100svh;
  min-height: 100%;

/////////////////
// Estilos de WrapperLight
  display: 'flex',
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: clamp(16px, 1.25vw + 12px, 36px),
  padding: clamp(16px, 1.25vw + 12px, 36px),
  background: #FFF,
  color: #010205,

  // Estilos de SkeletonLight
  ${getSkeletonStyle({ theme: 'light' })}

  @keyframes skeletonAnimation {
    from {
      background-position-x: 100%;
    }
    to {
      background-position-x: -100%;
    }
  }

  animation: skeletonAnimation 1.5s ease-in-out infinite;
`;

export const PVariablesCustomHydrator = createGlobalStyle`
:root {
  --p-modal-width: 100vw;
}
`;

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,  // A qué posición desplazarse, en este caso al principio
    behavior: 'smooth'  // Animación suave de desplazamiento
  });
};
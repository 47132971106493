// SkeletonComponent.jsx
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
    fontLineHeight,
    getSkeletonStyle,
} from '@porsche-design-system/components-react/styles';

// Definir estilos para cada tipo
const typeStyles = {
    box: css`
    height: 200px;
    width: 500px;
    border-radius: 8px;
  `,
    small: css`
    height: ${fontLineHeight}; /* Asegúrate de que fontLineHeight sea un valor válido como '20px' */
    width: 100px;
    border-radius: 4px;
  `,
    medium: css`
    height: ${fontLineHeight};
    width: 200px;
    border-radius: 4px;
  `,
    large: css`
    height: ${fontLineHeight};
    width: 300px;
    border-radius: 4px;
  `,
};

// Componente estilizado que aplica estilos según mode y type
const SkeletonWrapper = styled.div`
  ${(props) => getSkeletonStyle({ theme: props.mode })}
  ${(props) => typeStyles[props.type || 'box']}
`;

// Componente SkeletonComponent
export const SkeletonComponent = ({ mode = 'light', type = 'box' }) => {
    const validModes = ['light', 'dark'];
    const validTypes = ['box', 'small', 'medium', 'large'];

    const selectedMode = validModes.includes(mode) ? mode : 'light';
    const selectedType = validTypes.includes(type) ? type : 'box';

    return <SkeletonWrapper mode={selectedMode} type={selectedType} />;
};

// Validación de PropTypes
SkeletonComponent.propTypes = {
    mode: PropTypes.oneOf(['light', 'dark']),
    type: PropTypes.oneOf(['box', 'small', 'medium', 'large']),
};

// Valores por defecto para las props
SkeletonComponent.defaultProps = {
    mode: 'light',
    type: 'box',
};

import React from 'react';
import { PTextFieldWrapper, PButtonPure, PButton, PText, PFieldsetWrapper } from '@porsche-design-system/components-react';
import './RenderHumanDataFields.css';
import MinorsSection from '../MinorSection/MinorSection';
import { spacingFluidLarge, spacingFluidXLarge, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';

// const RenderHumanDataFields = ({ adults, setAdults, minors, setMinors, texts, handleMinorAgeChange, handleRemoveMinor, handleAddMinor }) => {
const RenderHumanDataFields = ({ adults, setAdults, minors, setMinors, texts, handleMinorAgeChange, handleRemoveMinor, handleAddMinor, errors, headerText }) => {

    const incrementPersons = () => {
        setAdults(prevAdults => prevAdults + 1);
    };

    const decrementPersons = () => {
        setAdults(prevAdults => (prevAdults > 1 ? prevAdults - 1 : 1)); // Asegurarse de no bajar de 1
    };

    return (
        // <div className="humanData">
        // <div
        //     className='humanData'
        //     style={{ gap: spacingFluidXSmall }}
        // >

        <PFieldsetWrapper label={headerText}>
            {/* <PText
                style={{ marginBottom: spacingFluidXSmall }}
                size="large">{headerText}</PText> */}

            <div
                className='adultWrapperHumanData'
                style={{
                    gap: spacingFluidXSmall,
                    marginBottom: spacingFluidXSmall
                }}
            >
                <PTextFieldWrapper
                    label={`${texts?.adults?.head}:`}
                    className="adultTextFieldHumanData"
                >
                    <input
                        type="number"
                        value={adults}
                        onChange={(e) => setAdults(parseInt(e.target.value, 10))}
                        min="1"
                    />
                </PTextFieldWrapper>
                <PButton
                    icon="subtract"
                    onClick={decrementPersons}
                    hideLabel={true}
                    disabled={adults <= 1}
                    aria-label={texts?.adults?.aria?.decrease}
                    className="adultIncreaseButtonHumanData"
                />
                <PButton
                    icon="add"
                    onClick={incrementPersons}
                    aria-label={texts?.adults?.aria?.increase}
                    hideLabel={true}
                    className="adultDecreaseButtonHumanData"
                />

            </div>

            <MinorsSection
                minors={minors}
                setMinors={setMinors}
                texts={texts?.minors}
                handleMinorAgeChange={handleMinorAgeChange}
                handleRemoveMinor={handleRemoveMinor}
                handleAddMinor={handleAddMinor}
            />
        </PFieldsetWrapper>
        // </div>
    );
};

export default RenderHumanDataFields;

"use client";

import { useEffect, useState } from "react";
import { useTheme } from "next-themes";

import "./BorderedContainer.css"

const BorderedContainer = ({ children }) => {
    const { theme } = useTheme();
    const [color, setColor] = useState("#ffffff");

    useEffect(() => {
        setColor(theme === "dark" ? "#ffffff" : "#2596be");
    }, [theme]);

    return (
        <div className="borderedContainerComponent">
            {children}
        </div>

    );
};

export default BorderedContainer;

// src/mainApp/pages/NotFound/NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NotFound.module.css';
import useTexts from 'mainApp/assets/utils/hooks/useTexts';

const NotFound = () => {
    const navigate = useNavigate();
    const { texts } = useTexts("404");

    return (
        <div className={styles.notFoundContainer}>
            <h1 className={styles.title}>404 - {texts?.notFound?.head}</h1>
            <p className={styles.description}>{texts?.notFound?.content}</p>
            <button onClick={() => navigate('/')} className={styles.backButton}>
                {texts?.notFound?.button}
            </button>
        </div>
    );
};

export default NotFound;

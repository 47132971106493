import { PSelect, PSelectOption, PSelectWrapper } from '@porsche-design-system/components-react';
import { LanguageContext } from 'mainApp/assets/utils/context/LanguageContext';
import React, { useContext } from 'react';
import './LanguageSelector.css';
import { spacingFluidMedium, spacingFluidXSmall } from '@porsche-design-system/components-js/styles';
import { IsRotatedContext } from 'mainApp/assets/utils/context/isRotatedContext';

const LanguageSelector = ({ className, isPhone }) => {
    const { language, setLanguage } = useContext(LanguageContext);

    const handleChange = (e) => {
        setLanguage(e.target.value);
    };

    const { isRotated } = useContext(IsRotatedContext);
    return (
        <>
            {/* {isPhone ?
                ( */}
            < select name="language"
                className={className ? className : "languageSelector"}
                onChange={handleChange}
                style={{ padding: isPhone ? isRotated ? "" : "" : 4}}
            >
                <option value="es">Español</option>
                <option value="en">English</option>
                <option value="fr">Français</option>

            </select >
            {/* ) : (
                    <PSelect
                        name="options" value={language}
                        onUpdate={handleChange}
                        className={className ? className : "languageSelector"}
                    >
                        <PSelectOption value="es">Español</PSelectOption>
                        <PSelectOption value="en">English</PSelectOption>
                        <PSelectOption value="fr">Français</PSelectOption>
                    </PSelect>
                )
            } */}

        </>
    );
};

export default LanguageSelector;

import { useState, useEffect, useContext, useCallback } from 'react';
import { LanguageContext } from '../context/LanguageContext';

const useTexts = (page) => {
    const { language, fetchTexts } = useContext(LanguageContext);
    const [texts, setTexts] = useState(null);

    const loadTexts = useCallback(async () => {
        const data = await fetchTexts(language, page);
        setTexts(data);
    }, [language, fetchTexts, page]);

    // Monitorear cambios en la caché y actualizar el estado local
    useEffect(() => {
        loadTexts();
    }, [loadTexts]);

    return {
        texts,
    };
};

export default useTexts;
